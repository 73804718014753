import axios from 'axios'
import store from '@/store'
import config from '@/config.js'

// create an axios instance
const service = axios.create({
  timeout: 10000, // request timeout
  baseURL: config.apiURL
})

// request interceptor
service.interceptors.request.use((config) => {
    config.headers['Accept-Language'] = store.state.locale
    if (store.state.userToken) {
      config.headers['Accept-Token'] = store.state.userToken
    }
    return config
  },
  error => {
    // do something with request error
    // console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.code === 20000) {
      return res
    } else if (res.code === 20020) {
      return res
    }
    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    if (res.code >= 50050) {
      // to re-login
      store.commit('userToken', '')
      // store.commit('userId', '')
    }
    return Promise.reject(new Error(res.message || 'Error'))
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default service
