<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
body{
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}
input{
  /* background-color: transparent !important; */
  /* color: white !important; */
  font-weight: bold;
}
input::-webkit-input-placeholder{
  color: #888 !important;
}
input::-moz-placeholder{
  color: #888 !important;
}
input::placeholder{
  color: #888 !important;
}
.el-input__prefix,
.el-input__suffix,
.el-input__clear{
  color: #333 !important;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0; 
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
