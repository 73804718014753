import Vue from 'vue'
import App from './App.vue'

import store from './store'

import { i18n } from './langs'

import ElementUI from 'element-ui';
import './styles/theme.scss'
// import 'element-ui/lib/theme-chalk/index.css';
// import './styles/style.scss';
import locale from 'element-ui/lib/locale/lang/en'
Vue.use(ElementUI, { locale });

import router from './router'
import axios from './services/axios'
import requestNotState from './services/requestNotState'

Vue.prototype.$request = axios
Vue.prototype.$requestNotState = requestNotState

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
