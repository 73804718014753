import store from '@/store.js'
import zh_TW from './zh-TW'
import en from './en'
import de from './de'
import fr from './fr'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
export const i18n = new VueI18n({
  locale: store.state.locale,
  messages: {
    en,
    de,
    fr,
    'zh-Hant': zh_TW
  }
})